import React from 'react'

export default function Maps() {
  return (
    <div className='maps'>
        <h2>Our Locations</h2>
        <iframe className="maps" title="Maps" src="https://www.google.com/maps/d/embed?mid=1vlP065ps4WU7MvAbnllDqNBU2IIu94o&ehbc=2E312F" 
        width="1200" height="480"></iframe>
    </div>
  )
}
