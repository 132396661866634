import React from 'react'

function Constitution() {
  return (
    <div>
      <h3>Tumaini Ministries Constitution</h3>
    </div>
  )
}

export default Constitution
