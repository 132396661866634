import { Link } from 'react-router-dom';

const Church = () => {
  return (
    <>
      <h2>See Our Locations across Kenya</h2>

      <Link to="/web-design">Web design</Link>
      <Link to="/seo">Seo</Link>
      <Link to="/web-design">Web design</Link>
      <Link to="/web-design">Web design</Link>
      <Link to="/web-design">Web design</Link>
      <Link to="/web-design">Web design</Link>
      <Link to="/web-design">Web design</Link>
      <Link to="/web-design">Web design</Link>
      
    </>
  );
};

export default Church;